import React, { useState, useEffect } from "react";
import "../App.css"; // Importing CSS for styling

function Settings() {
  const [selectedRadio, setSelectedRadio] = useState(""); // State for the selected radio button
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [order, setOrder] = useState("");
  const [isConnected, setIsConnected] = useState(false); // Track Facebook connection status
  const [facebookUser, setFacebookUser] = useState(null); // Store Facebook user data

  const [pixelCheckboxes, setPixelCheckboxes] = useState({
    viewContent: false,
    pageView: false,
    initiateCheckout: false,
    search: false,
    addToCart: false,
    purchase: false,
  });

  const [conversationCheckboxes, setConversationCheckboxes] = useState({
    viewContent: false,
    pageView: false,
    initiateCheckout: false,
    search: false,
    addToCart: false,
    purchase: false,
  });

  const timezones = [
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "America/New_York",
    "America/Chicago",
    "America/Denver",
    "America/Los_Angeles",
    "Asia/Dubai",
    "Asia/Kolkata",
    "Asia/Singapore",
    "Asia/Tokyo",
    "Europe/London",
    "Europe/Paris",
    "Europe/Berlin",
    "Europe/Moscow",
    "Pacific/Auckland",
    "Pacific/Honolulu",
    "Australia/Sydney",
    "Antarctica/Casey",
  ];

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value); // Update selected radio button
  };

  const handleOrderChange = (e) => {
    setOrder(e.target.value); // Update selected radio button
  };

  const handleTimezoneChange = (e) => {
    setSelectedTimezone(e.target.value); // Update selected timezone
  };

  // Handle checkbox changes for pixel tracking
  const handlePixelCheckboxChange = (e) => {
    setPixelCheckboxes({
      ...pixelCheckboxes,
      [e.target.name]: e.target.checked,
    });
  };

  // Handle checkbox changes for conversation API
  const handleConversationCheckboxChange = (e) => {
    setConversationCheckboxes({
      ...conversationCheckboxes,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("selectedRadio", selectedRadio);
    formData.append("selectedTimezone", selectedTimezone);
    formData.append("orderFacebook", order);
    // Add pixel checkboxes to form data
    Object.keys(pixelCheckboxes).forEach((key) => {
      formData.append(`pixel_${key}`, pixelCheckboxes[key]);
    });

    // Add conversation checkboxes to form data
    Object.keys(conversationCheckboxes).forEach((key) => {
      formData.append(`conversation_${key}`, conversationCheckboxes[key]);
    });

    console.log("FormData entries:", Array.from(formData.entries()));

    // Example API call (uncomment when your API is ready)
    // fetch("your-api-endpoint", {
    //   method: "POST",
    //   body: formData,
    // })
    //   .then((response) => response.json())
    //   .then((data) => console.log("Success:", data))
    //   .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    // Load the SDK asynchronously
    const loadFacebookSDK = () => {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "1017403406853694", // Replace with your Facebook App ID
          cookie: true,
          xfbml: true,
          version: "v16.0", // Use a valid version
        });

        // Check login status
        window.FB.getLoginStatus(function (response) {
          handleStatusChange(response);
        });
      };

      // Adding the script to the document
      const script = document.createElement("script");
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    loadFacebookSDK();
  }, []);

  const handleStatusChange = (response) => {
    if (response.status === "connected") {
      fetchFacebookUser();
    } else {
      setIsConnected(false);
      setFacebookUser(null);
    }
  };

  // Fetch user data from Facebook after connection
  const fetchFacebookUser = () => {
    window.FB.api("/me", { fields: "name,picture" }, function (response) {
      setIsConnected(true);
      setFacebookUser({
        name: response.name,
        imageUrl: response.picture.data.url,
      });
    });
  };

  // Handle Facebook login
  const handleConnect = () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          fetchFacebookUser(); // Fetch user data after successful login
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "public_profile,email" } // Request permissions
    );
  };

  // Handle Facebook logout (disconnect)
  const handleDisconnect = () => {
    const confirmDisconnect = window.confirm(
      "Are you sure you want to disconnect? Your connection data will be deleted."
    );
    if (confirmDisconnect) {
      window.FB.logout(function (response) {
        setIsConnected(false);
        setFacebookUser(null);
      });
    }
  };

  return (
    <>
      {/* First Card */}
      <div className="card1">
        <div className="box">
          <label>You Need to embed app into your theme</label>
        </div>
        <p>
          Embed the theme-app extension for the app to start working properly.
          This is required for all themes.
        </p>
        <div>
          <button className="embed-btn">Go to Embed</button>
        </div>
      </div>

      {/* Second Card */}
      <div className="card1">
        <div className="display">
          <div className="col-md-6">
            <h4>Connect Facebook account</h4>
            <p>Use Auto input Pixel, Catalog and Facebook Ads Report</p>
          </div>
          <div className="col-md-6">
            <div className="card2">
              {isConnected && facebookUser ? (
                <>
                  <p>Connected as {facebookUser.name}</p>
                  <img
                    src={facebookUser.imageUrl}
                    alt="Facebook User"
                    style={{ width: "50px", borderRadius: "50%" }}
                  />
                  <button className="embed-btn" onClick={handleDisconnect}>
                    Disconnect
                  </button>
                </>
              ) : (
                <>
                  <p>You are Not Connected</p>
                  <button className="embed-btn" onClick={handleConnect}>
                    Connect
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card1">
        {/* Section 2 - General Settings */}
        <div className="display">
          <div className="col-md-6">
            <h4>General Settings</h4>
            <p>
              Contain general app settings such as status, language, timezone,
              etc.
            </p>
          </div>
          <div className="col-md-6">
            <div className="card2">
              <div className="section">
                <h3>App - Multi Facebook Pixels app</h3>
                <p>
                  If you disable the app. it will stop all pixel tracking
                  activities; however, the catelog/Feed will still function
                </p>
                <button className="embed-btn">Disable App</button>
              </div>
              <div className="section">
                <h3>Your Facebook Ads Account Timezone</h3>
                <p>
                  We recommend that you set the timezone to match with Facebook
                  Ads to ensure events are sent accurately.
                </p>
                <div>
                  <select
                    onChange={handleTimezoneChange}
                    value={selectedTimezone}
                  >
                    <option value="">Select Timezone</option>
                    {timezones.map((timezone) => (
                      <option key={timezone} value={timezone}>
                        {timezone}
                      </option>
                    ))}
                  </select>
                </div>
                <a>Where Can I Find it?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card1">
        {/* Section 3 - Event Settings */}
        <div className="display">
          <div className="col-md-6">
            <h4>Event Settings</h4>
            <p>Select the events you want to send and not send to Facebook.</p>
            <p>Custom Params events sent to Facebook Delay Pixel tracking.</p>
          </div>
          <div className="col-md-6">
            <div className="card2">
              <div className="section">
                <p>Select the events you want to send using the pixel</p>
                <label>
                  <input
                    type="checkbox"
                    name="viewContent"
                    checked={pixelCheckboxes.viewContent}
                    onChange={handlePixelCheckboxChange}
                  />{" "}
                  View Content
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="pageView"
                    checked={pixelCheckboxes.pageView}
                    onChange={handlePixelCheckboxChange}
                  />{" "}
                  Page View
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="initiateCheckout"
                    checked={pixelCheckboxes.initiateCheckout}
                    onChange={handlePixelCheckboxChange}
                  />{" "}
                  Initiate Checkout
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="search"
                    checked={pixelCheckboxes.search}
                    onChange={handlePixelCheckboxChange}
                  />{" "}
                  Search
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="addToCart"
                    checked={pixelCheckboxes.addToCart}
                    onChange={handlePixelCheckboxChange}
                  />{" "}
                  Add to Cart
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="purchase"
                    checked={pixelCheckboxes.purchase}
                    onChange={handlePixelCheckboxChange}
                  />{" "}
                  Purchase
                </label>
              </div>
              <hr />
              <div className="section">
                <p>
                  Select the events you want to send using the conversation API
                </p>
                <label>
                  <input
                    type="checkbox"
                    name="viewContent"
                    checked={conversationCheckboxes.viewContent}
                    onChange={handleConversationCheckboxChange}
                  />{" "}
                  View Content
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="pageView"
                    checked={conversationCheckboxes.pageView}
                    onChange={handleConversationCheckboxChange}
                  />{" "}
                  Page View
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="initiateCheckout"
                    checked={conversationCheckboxes.initiateCheckout}
                    onChange={handleConversationCheckboxChange}
                  />{" "}
                  Initiate Checkout
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="search"
                    checked={conversationCheckboxes.search}
                    onChange={handleConversationCheckboxChange}
                  />{" "}
                  Search
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="addToCart"
                    checked={conversationCheckboxes.addToCart}
                    onChange={handleConversationCheckboxChange}
                  />{" "}
                  Add to Cart
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="purchase"
                    checked={conversationCheckboxes.purchase}
                    onChange={handleConversationCheckboxChange}
                  />{" "}
                  Purchase
                </label>
              </div>
              <hr />
              <div className="section">
                <p>
                  Select the value of the content_id params (if you are not
                  using Catalog/Feed, please ignore this).
                </p>
                <label>
                  <input
                    type="radio"
                    name="content_id"
                    value="variant_id"
                    checked={selectedRadio === "variant_id"}
                    onChange={handleRadioChange}
                  />{" "}
                  Variant_id
                </label>
                <label>
                  <input
                    type="radio"
                    name="content_id"
                    value="product_id"
                    checked={selectedRadio === "product_id"}
                    onChange={handleRadioChange}
                  />{" "}
                  Product_id
                </label>
                <hr />
                <label>
                  <input
                    type="checkbox"
                    checked={order}
                    onChange={handleOrderChange}
                  />{" "}
                  The order value sent to Facebook includes shipping fees, taxes
                  and duties
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card1">
        {/* Sync Data Section */}
        <div className="display">
          <div className="col-md-6">
            <h4>Sync Data</h4>
            <p>
              If you cannot find the Product/Collection, please click Sync Data
              so that the App System can retrieve the Product/Collection
              information from your store. This may take a few minutes.
            </p>
          </div>
          <div className="col-md-6">
            <div className="card2">
              <div className="section">
                <p>Last sync time 2024-10-09 09:57:05</p>
              </div>
              <div>
                <button className="embed-btn">Sync Data</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Save Button */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button onClick={handleSubmit} className="embed-btn">
          Save Settings
        </button>
      </div>
    </>
  );
}

export default Settings;
