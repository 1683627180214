import React, { useState, useEffect } from "react";
import "../App.css"; // Importing CSS for styling
import { useNavigate } from "react-router-dom"; // Use this if using react-router for navigation
import Product from "../modals/Product";
import Collection from "../modals/Collection";
import { updateConditions, getConditions } from "../services/settingService";

function PixelAddScreen() {
  const [activeTab, setActiveTab] = useState("auto"); // Default tab is "Auto Input"
  const [apiChecked, setApiChecked] = useState(false); // Checkbox to toggle API fields
  const [selectedOption, setSelectedOption] = useState("all"); // Radio button state
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const shop = "urgancy2.myshopify.com";

  const [formData, setFormData] = useState({
    products: [],
    collections: [],
    pixelName: "", // For manual input tab
    pixelId: "",
  });

  const [iosData, setIosData] = useState({
    facebook_access_token: "",
    test_event_code: "",
  });
  const handleIOSChange = (field, value) => {
    setIosData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const submitIOSBtn = async () => {
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    console.log("IOS Data:", iosData);
    // try {
    //   const response = await updateIOSSettings(iosData); // API call for iOS-related data
    //   console.log("iOS data submitted successfully:", response.data);
    //   setSuccessMessage(response.data.message);
    //   window.location.reload();
    // } catch (error) {
    //   console.error("Error submitting iOS data:", error);
    //   setErrorMessage("Error submitting iOS data. Please try again.");
    // } finally {
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    console.log("Modal state changed:", isOpen);
  }, [isOpen]);
  // Handle tab switch
  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
  };

  const handleStoreData = (selectedProductIds) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      products: JSON.parse(selectedProductIds),
    }));
    console.log("Updated formData:", formData);
  };

  const handleCollectionStoreData = (selectedCollectionIds) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      collections: JSON.parse(selectedCollectionIds),
    }));
    console.log("Updated formData handleCollectionStoreData:", formData);
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    if (field === "positionAlignment") {
      setFormData((prevData) => ({
        ...prevData,
        customCSS: `text-align: ${value}`,
      }));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    console.log("Form Data:", formData);
    // setTimeout(async () => {
    //   try {
    //     const response = await updateConditions(formData);
    //     console.log("Data submitted successfully:", response.data);
    //     setSuccessMessage(response.data.message);
    //     window.location.reload();
    //   } catch (error) {
    //     console.error("Error submitting data:", error);
    //     setErrorMessage("Error submitting data. Please try again.");
    //   } finally {
    //     setLoading(false);
    //   }
    // }, 2000);
  };

  useEffect(() => {
    // Example of side effect, if needed
    console.log("Modal state changed:", isOpen);
  }, [isOpen]);

  // Handle radio button change
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <div className="card">
        {/* Tabs */}
        <div className="tabs">
          <button
            className={`tab-btn ${activeTab === "auto" ? "active" : ""}`}
            onClick={() => handleTabSwitch("auto")}
          >
            Auto Input
          </button>
          <button
            className={`tab-btn ${activeTab === "manual" ? "active" : ""}`}
            onClick={() => handleTabSwitch("manual")}
          >
            Manual Input
          </button>
        </div>

        {/* Tab Content */}
        <div className="tab-content">
          {activeTab === "auto" && (
            <div className="auto-input">
              <h3>Connect your Facebook account</h3>
              <p>No account connected.</p>
              <button className="connect-btn">Connect</button>
            </div>
          )}

          {activeTab === "manual" && (
            <div className="manual-input">
              <button className="connect-btn" onClick={handleSubmit}>
                {" "}
                Save
              </button>
              <h3>Manual Input</h3>
              <label>Name your pixel*</label>
              <input
                type="text"
                placeholder="Any name will do. This is just so you can manage different pixels easily."
                className="input-field"
                value={formData.pixelName}
                onChange={(e) => handleInputChange("pixelName", e.target.value)}
              />
              <label>
                Pixel ID* <a>How I get it?</a>
              </label>
              <input
                type="text"
                placeholder="Copy pixel ID from Facebook and paste here."
                className="input-field"
                value={formData.pixelId}
                onChange={(e) => handleInputChange("pixelId", e.target.value)}
              />
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    value="all"
                    checked={selectedOption === "all"}
                    onChange={handleRadioChange}
                  />
                  All Pages
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    value="selected"
                    checked={selectedOption === "selected"}
                    onChange={handleRadioChange}
                  />
                  Selected Pages
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    value="excluded"
                    checked={selectedOption === "excluded"}
                    onChange={handleRadioChange}
                  />
                  Excluded Pages
                </label>
                <br />
              </div>

              {/* Conditionally render buttons based on selected radio option */}
              {selectedOption === "selected" && (
                <div className="button-group">
                  <button
                    className="btn"
                    onClick={() => {
                      setIsOpen2(true);
                      handleInputChange("collectionChecked", "selected");
                    }}
                  >
                    + Select Collection(s)
                  </button>{" "}
                  <br />
                  <button
                    className="btn"
                    onClick={() => {
                      setIsOpen(true);
                      handleInputChange("productTypeChecked", "selected");
                    }}
                  >
                    + Product With Type(s)
                  </button>
                  <br />
                  <button
                    className="btn"
                    onClick={() => {
                      setIsOpen(true);
                      handleInputChange("productTagChecked", "selected");
                    }}
                  >
                    + Product With Tags(s)
                  </button>{" "}
                  <br />
                  <button
                    className="btn"
                    onClick={() => {
                      setIsOpen(true);
                      handleInputChange("productChecked", "selected");
                    }}
                  >
                    + Select Product(s)
                  </button>{" "}
                  <br />{" "}
                </div>
              )}
            </div>
          )}

          {/* Conditionally render buttons based on selected radio option */}
          {selectedOption === "excluded" && (
            <div className="button-group">
              <button
                className="btn"
                onClick={() => {
                  setIsOpen2(true);
                  handleInputChange("collectionChecked", "excluded");
                }}
              >
                + Select Collection(s)
              </button>{" "}
              <br />
              <button
                className="btn"
                onClick={() => {
                  setIsOpen2(true);
                  handleInputChange("productTypeChecked", "excluded");
                }}
              >
                + Product With Type(s)
              </button>
              <br />
              <button
                className="btn"
                onClick={() => {
                  setIsOpen2(true);
                  handleInputChange("productTagChecked", "excluded");
                }}
              >
                + Product With Tags(s)
              </button>{" "}
              <br />
              <button
                className="btn"
                onClick={() => {
                  setIsOpen(true);
                  handleInputChange("productChecked", "excluded");
                }}
              >
                + Select Product(s)
              </button>{" "}
              <br />{" "}
            </div>
          )}
        </div>

        {/* Section Below Tabs */}
        <div className="section-below-tabs">
          <h4>
            <label>
              <input
                type="checkbox"
                checked={apiChecked}
                onChange={() => setApiChecked(!apiChecked)}
              />
              Conversation API (solution for IOS 14.5)
            </label>
            <p>
              Enable server-side API to track all customer behavior events
              bypassing the browser's limitation, or ad-blockers.
            </p>
          </h4>
          {apiChecked && (
            <div className="api-url-fields">
              <label>Fill Facebook Access Token</label>
              <input
                type="text"
                className="input-field"
                onChange={(e) =>
                  handleIOSChange("facebook_access_token", e.target.value)
                }
              />
              <label>Test Event Code</label>
              <input
                type="text"
                className="input-field"
                onChange={(e) =>
                  handleIOSChange("test_event_code", e.target.value)
                }
              />
              <p>
                Use this if you need to test the server-side event. Remove it
                after testing.
              </p>
              <button className="connect-btn" onClick={submitIOSBtn}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <Product
          set={setIsOpen}
          onStoreData={handleStoreData}
          data={formData.products}
          shop={shop}
        />
      )}
      {isOpen2 && (
        <Collection
          set={setIsOpen2}
          onStoreData={handleCollectionStoreData}
          data={formData.collections}
          shop={shop}
        />
      )}
    </>
  );
}

export default PixelAddScreen;
