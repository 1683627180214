import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PixelAddScreen from "./components/pixelAddScreen";
import PixelScreen from "./components/pixelScreen";
import InstallPage from "./components/InstallPage";
import ShopifyCallback from "./components/ShopifyCallback";
import Settings from "./components/Setting";
function App() {
  return (
    <Router>
      <Routes>
	  <Route path="/shopify/callback" element={<ShopifyCallback />} />
       <Route path="/shopify/install" element={<InstallPage />} />
       <Route path="/shopify/app_view" element={<Settings />} />
        <Route path="/pixels" element={<PixelScreen />} />
        <Route path="/add/pixel" element={<PixelAddScreen />} />
      </Routes>
    </Router>
  );
}

export default App;
