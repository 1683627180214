import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Use this if using react-router for navigation
import "../App.css"; // Importing CSS for styling

function PixelScreen() {
  const [searchTerm, setSearchTerm] = useState("");

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="card">
      {/* Add Button */}
      <button className="add-btn">
        <a href="/add/pixel">Add</a>
      </button>

      {/* Search Input */}
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-input"
      />

      {/* Display No Data Found Message */}
      <div className="no-data">
        <p>No Data Found</p>
      </div>
    </div>
  );
}

export default PixelScreen;
