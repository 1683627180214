import React, { useEffect } from 'react';
import axios from 'axios';
import Loader from '../img/loader.gif'


const ShopifyCallback = () => {
    useEffect(() => {
        const fetchToken = async () => {
            const query = new URLSearchParams(window.location.search);
            const code = query.get('code');
            const shop = query.get('shop');
            const hmac = query.get('hmac');
            const state = query.get('state');

            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_APP_HOST}/shopify/callback`, {
                    params: { code, shop, hmac, state }
                });

                // Redirect to app page or show success message
                window.location.href = `https://${shop}/admin/apps/${process.env.REACT_APP_SHOPIFY_API_KEY}`;
            } catch (error) {
                console.error('Error during installation:', error);
            }
        };

        fetchToken();
    }, []);

    return <div style={{margin:'auto', display:'flex',justifyContent:'center',alignItems:'center'}}><img src={Loader} /></div>;
};

export default ShopifyCallback;
